import * as Sentry from "@sentry/nextjs";
import { Button } from "flowbite-react";
import Link from "next/link";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";

export const ErrorFrame: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  // TODO add more context
  const router = useRouter();
  // Log error in Sentry and Posthog
  useEffect(() => {
    posthog.capture("ErrorFrame", {
      path: router.asPath,
      children: children,
    });
    Sentry.captureException(new Error("ErrorFrame"));
  }, [router.asPath, children]);
  const message =
    children ??
    "An unexpected error occurred.\n\n Please reload this page or\ncontact support if the problem persists.";
  return (
    <div className="flex flex-grow items-center justify-center h-full flex-col">
      <div className="text-center max-w-md whitespace-pre-line my-8">
        {message}
      </div>

      <div className="flex gap-2">
        <Button
          href="#"
          color="gray"
          onClick={() => {
            router.back();
          }}
        >
          Go back
        </Button>
        <Link href="/">
          <Button>Go home</Button>
        </Link>
      </div>
    </div>
  );
};
