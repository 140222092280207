import { ErrorFrame } from "~/components/ErrorFrame";

export default function Custom404() {
  return (
    <ErrorFrame>
      <h1 className="font-bold mb-3">Error 404</h1>
      <h2>Page Not Found</h2>
    </ErrorFrame>
  );
}
